<template>
    <router-view />
</template>

<script>
export default {
    name: 'LangDetector',
    methods: {
        changeLang (lang) {
            this.$i18n.locale = lang
        }
    }
}
</script>